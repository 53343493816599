<template>
  <div class="bg-white">
    <div @click="close" class="w-full flex text-guestio-gray-500 md:text-white items-center justify-end space-x-3 pt-10 pr-10 cursor-pointer absolute">
      <div class="uppercase body-l-500">Close</div>
      <CloseIcon />
    </div>
    <div v-if="loading" class="max-w-md mx-auto flex items-center justify-center min-h-screen">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div v-else class="grid grid-cols-1" :class="{'lg:grid-cols-2' : showWhatHappensNext}">
      <!-- Left-col -->
      <div class="w-full max-w-lg mx-auto py-20 px-6 xl:px-0">
        <transition
          enter-active-class="transition ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          mode="out-in"
        >
          <router-view></router-view>
        </transition>
      </div>
      <!-- Right col -->
      <div
        v-if="showWhatHappensNext"
        class="p-10 bg-black flex flex-col items-center h-110%"
      >
        <div class="relative rounded-full w-48 h-48 mx-auto mt-16 mb-8 overflow-hidden">
          <img
            :src="show.imageUrl"
            class="w-full h-full object-cover"
            :alt="show.name"
          />
        </div>
        <h2 class="font-bold text-3xl text-white text-center mb-10">
          What happens next?
        </h2>
        <div>
          <ShowBookingNextSteps :user="user" :show="show" />
        </div>
      </div>
    </div>
    <ReconfirmLoginModal />
    <CloseBookingModal ref="closeBookingModal" />
  </div>
</template>

<script>
import { chain } from 'lodash';
import CloseBookingModal from '@/components/bookings/individual/activity/CloseBookingModal';
import ShowBookingNextSteps from "@/components/Book/show/ShowBookingNextSteps"
import ReconfirmLoginModal from '@/components/auth/ReconfirmLoginModal'
import CloseIcon from '@/components/svgs/CloseIcon';

export default {
  name: "ShowBook",

  components: {
    ShowBookingNextSteps,
    ReconfirmLoginModal,
    CloseIcon,
    CloseBookingModal,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    show() {
      return this.$store.getters['shows/show']
    },

    user() {
      return this.$store.getters['auth/getUser']
    },

    showWhatHappensNext() {
      return this.$route.name == 'ShowBookingPayment'
    },

    bookingOptions() {
      return this.$store.getters['showBooking/bookingOptions']
    },

    showOptionPrices() {
      const options = this.bookingOptions.filter(option => option.slug != 'pitch');
      return chain(options)
        .map(option => {
          return {
            ...option,
            prices: this.show.option_prices.filter(price => price.booking_option_id === option.id)
          }
        })
        .filter(option => option.prices.length)
        .sortBy(option => option.id)
        .value()
    },

    isPitch() {
      return this.$store.getters['showBooking/isPitch'];
    }
  },

  methods: {
    close() {
      this.$refs.closeBookingModal.toggle();
    }  
  },

  async created() {
    this.$store.commit('showBooking/clearBooking')

    await this.$store.dispatch('showBooking/fetchBookingOptions')

    if (this.$route.params.is_pitch) {
      this.$store.commit('showBooking/setIsPitch', true);
    }

    let { data: show } = await this.$store.dispatch('shows/fetchShow', {
      id: this.$route.params.showId,
      includes: 'author,pitch_settings',
    })

    if (show.author && show.author.isPaused) {
      return this.$router.push({name: 'NotFound'})
    }

    if (this.user.id == this.show.user_id) {
      return this.$router.push(`/shows/${this.show.id}`)
    }

    const isPitchEnabled = this.show.pitch_settings.is_enabled;
    if (this.isPitch && !isPitchEnabled) {
      return this.$router.push(`/shows/${this.show.id}`);
    }

    if (this.$route.params.bookingOptionId) {
      let option = this.showOptionPrices.find(option => option.id == this.$route.params.bookingOptionId)

      this.$store.commit('showBooking/setSelectedBookingOption', {
        ...option,
        price: option.prices[0],
      })
    }

    if (this.show.option_prices.length && !this.isPitch) {
      this.$store.commit('showBooking/enableStep', 'options')
    }

    const { data: preferences } =  await this.$store.dispatch('shows/fetchBookingFlowPreferences', this.show.id);

    if ((this.show.author && this.show.author.isPro) || this.isPitch) {
      if ((!this.isPitch && preferences.data.questions) || (this.isPitch && this.show.pitch_settings.questions)) {
        const { data: questions } =  this.isPitch
          ? await this.$store.dispatch('shows/fetchPitchQuestions', this.show.id)
          : await this.$store.dispatch('shows/fetchBookingQuestions', this.show.id);

        if (questions.data.length) {
          this.$store.commit('showBooking/setQuestions', questions.data)
          this.$store.commit('showBooking/enableStep', 'questions')
        }
      }

      if (preferences.data.upsells) {
        const { data: upsells } =  await this.$store.dispatch('shows/fetchUpsellPackages', this.show.id)

        if (upsells.data.length && preferences.data.upsells) {
          this.$store.commit('showBooking/setAvailableUpsellPackages', upsells.data)
          this.$store.commit('showBooking/enableStep', 'upsells')
        }
      }
    }

    if (! this.user.guest) {
      this.$store.commit('showBooking/enableStep', 'profile')
    } else {
      this.$store.commit('showBooking/setGuest', this.user.guest)
    }

    this.loading = false
  },

  beforeDestroy() {
    this.$store.commit('showBooking/clearBooking')
  },
};
</script>
<style scoped>
.body-l-500{
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}
</style>
