<template>
  <div class="flex flex-col items-center w-full xxl:w-7/12 mx-auto">
    <!-- Box 1 -->
    <div
      class="w-full flex items-center text-white bg-pureBlack px-6 py-6 rounded-lg mb-6"
    >
      <svg
        class="mr-10 flex-shrink-0"
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 1V5"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 1V5"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 9H19"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>{{ show.name }} has <b>7 Days</b> to accept your interview request.</span>
    </div>
    <!-- Box 2 -->
    <div
      class="w-full flex items-center text-white bg-pureBlack px-6 py-6 rounded-lg mb-6"
    >
      <svg
        class="mr-10 flex-shrink-0"
        width="22"
        height="18"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 1H19C20.1 1 21 1.9 21 3V15C21 16.1 20.1 17 19 17H3C1.9 17 1 16.1 1 15V3C1 1.9 1.9 1 3 1Z"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 3L11 10L1 3"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span
        >Order details will be sent to your email
        <b>{{ user.email }}</b> immediately.</span
      >
    </div>
    <!-- Box 3 -->
    <div
      class="w-full flex items-center text-white bg-pureBlack px-6 py-6 rounded-lg mb-6"
    >
      <svg
        class="mr-10 flex-shrink-0"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H5L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13Z"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span>
        When {{ show.name }} accepts your request, you´ll have messenger access to schedule the interview.
      </span>
    </div>
    <!-- Box 4 -->
    <div
      class="w-full flex items-center text-white bg-pureBlack px-6 py-6 rounded-lg mb-6"
    >
      <svg
        class="mr-10 flex-shrink-0"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 15C13.2091 15 15 13.2091 15 11C15 8.79086 13.2091 7 11 7C8.79086 7 7 8.79086 7 11C7 13.2091 8.79086 15 11 15Z"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.92993 3.92999L8.16993 8.16999"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.8301 13.83L18.0701 18.07"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.8301 8.16999L18.0701 3.92999"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.8301 8.17L17.3601 4.64"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.92993 18.07L8.16993 13.83"
          stroke="#DC3CF6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span>
        If for some reason {{ show.name }} does not confirm the interview or no response is given in 30 days, any paid amount will be refunded in <span class="font-bold">5-7 business days</span>.
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      user: Object,
      show: Object,
    }
  }
</script>
